import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const CategoryPost = ({categoryPost}) => {
  // console.log(categoryPost.categories); 
  return (
      <div className="w-full mt-8 first:mt-4" key={categoryPost.wordpress_id}>
        <h2>
          <Link className="hover:underline" aria-label={categoryPost.title} to={categoryPost.categories.nodes[0].uri + categoryPost.slug}>
            {categoryPost.title}
          </Link>
          <span className="hidden text-xs font-normal -mt-2 text-scplightgrey sm:block">
            Posted by {categoryPost.author.node.name} on {categoryPost.date}
          </span>
        </h2>
      </div> 
  )
}

CategoryPost.propTypes = {
    categoryPost: PropTypes.object.isRequired,
}

export const query = graphql`
  fragment CategoryPostFragment on WpPost {
    title
    slug
    date(formatString: "YYYY-MM-DD")
    categories {
      nodes {
        uri
      }
    }
    author {
      node {
        name
      }
    }
  }
`

export default CategoryPost
