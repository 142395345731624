import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import CategoryPost from "../components/category-post"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const CategoryPostBlock = ({categoryPosts}) => {
  return (
    <div className="mb-16">
        {categoryPosts.map((postEdge, i) => (
            <CategoryPost categoryPost={postEdge.node} key={postEdge.node.wordpress_id}/>
        ))}
    </div> 
  )
}

CategoryPostBlock.propTypes = {
  categoryPosts: PropTypes.arrayOf(PropTypes.object).isRequired,
}

CategoryPostBlock.defaultProps = {
}

export const query = graphql`
  fragment CategoryPostsFragment on WpPostConnection {
    edges {
      node {
        databaseId
        ...CategoryPostFragment
      }
    }
  }
`

export default CategoryPostBlock
